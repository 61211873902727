<template>
  <b-container class="bg-white rounded shadow-sm m-0 w-100" ref="productList">
    <div class="col-12">
      <InfoVideo
        title="Como funciona"
        video-url="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
      />
    </div>
    <div class="text-left px-5" v-if="!loading">
      <h5 class="mb-3 text-green font-weight-bold">
        Compartilhe e ganhe mais!
      </h5>
      <p class="lead">
        Aproveite nossos produtos incríveis e convide seus amigos para também
        economizarem com cashback. Quanto mais pessoas você indicar, mais
        oportunidades de ganhar!
      </p>
      <p class="my-3">
        Escolha abaixo os produtos que mais gosta e compartilhe com sua rede.
        Seus amigos ganham descontos especiais e você acumula ainda mais
        cashback nas compras.
      </p>
    </div>

    <b-row v-if="!loading">
      <!-- Ref para o container -->
      <b-col
        v-for="product in products"
        :key="product._id"
        cols="12"
        md="6"
        lg="6"
        class="mb-4"
      >
        <b-card :img-src="product.aw_image_url" :img-alt="product.product_name">
          <b-card-body>
            <b-card-text
              style="min-height: 150px"
              class="d-flex align-items-center justify-content-center text-center h4 text-green"
            >
              {{ product.product_name }}
            </b-card-text>

            <div class="text-center text-blue">
              <div class="lead text-center"><small>A partir de</small></div>
              <div class="text-xl font-weight-bold">
                <div class="">{{ formatCurrency(product.search_price) }}</div>
                <div class="lead">
                  <small><span>à vista</span></small>
                </div>
              </div>
            </div>
          </b-card-body>
          <b-card-footer>
            <b-button
              @click="copyLink(product.aw_product_id)"
              target="_blank"
              variant="primary"
              class="d-flex align-items-center justify-content-between share mt-3 btn-block"
            >
              Pegar link
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
                class="w-5 h-5 ml-2"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
                  clip-rule="evenodd"
                ></path>
                <path
                  fill-rule="evenodd"
                  d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-center pb-5"
      >
        <b-button class="green-button-2" @click="showMore()">
          Mostrar mais produtos
        </b-button>
      </b-col>
    </b-row>

    <div v-if="loading" class="text-center my-4">
      <b-spinner class="w-5 h-5 text-green" label="Carregando..."></b-spinner>
      <p class="text-green">Carregando produtos, por favor aguarde...</p>
    </div>
  </b-container>
</template>

<script>
import InfoVideo from "../JornadaMetaMinuto/InfoVideo.vue";

export default {
  components: {
    InfoVideo,
  },
  data() {
    return {
      products: [],
      page: 1,
      limit: 4,
      loading: true,
    };
  },
  mounted() {
    this.fetchProducts(this.page, this.limit);
  },
  methods: {
    async showMore() {
      this.scrollToTop(); // Chama a função para rolar para o início
      this.page = this.page + 1;
      await this.fetchProducts(this.page, this.limit);
    },
    async fetchProducts(page, limit) {
      this.loading = true;
      try {
        const url = `https://qnta.me/api/products?page=${page}&limit=${limit}`;
        const response = await fetch(url, { mode: "cors" });
        const data = await response.json();
        this.products = data.products;
      } catch (error) {
        console.error("Erro ao buscar produtos:", error);
      } finally {
        this.loading = false;
      }
    },
    // scrollToTop() {
    //   this.$refs.productList.scrollIntoView({ behavior: "smooth" }); // Rola suavemente para o container de produtos
    // },
    scrollToTop() {
      const element = this.$refs.productList;
      element.scrollIntoView({ behavior: "smooth" });

      // Ajusta a rolagem para cima em 10 pixels
      const offset = 10; // Defina o deslocamento desejado
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset; // Obtém a posição do elemento
      const offsetPosition = elementPosition - offset; // Calcula a nova posição

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth", // Rolagem suave
      });
    },
    copyLink(productId) {
      const link = `https://qnta.me/${productId}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          this.$snotify.success("Link copiado! Agora é só compartilhar.");
        })
        .catch((err) => {
          console.error("Erro ao copiar o link: ", err);
        });
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    },
  },
};
</script>

<style scoped>
.w-5 {
  width: 1.25rem;
}

.h-5 {
  height: 1.25rem;
}

.text-green {
  color: #1e5d68;
}

.card {
  min-height: 500px;
}

.share {
  background-color: rgb(36 180 93 / 1);
  border-color: rgb(36 180 93 / 1);
}

.share:hover {
  background-color: rgb(27 135 70 / 1);
  border-color: rgb(27 135 70 / 1);
}

.share:focus,
.share:active {
  background-color: rgb(27 135 70 / 1) !important;
  border-color: rgb(27 135 70 / 1) !important;
  outline: 2px solid rgb(36 180 93 / 1) !important;
  outline-offset: 2px !important;
  box-shadow: none !important;
}

.text-blue {
  color: #003b7b;
}

.green-button-2 {
  background-color: #1e5d68 !important;
  color: white !important;
  border: 0;
  border-radius: 6px;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  font-weight: 500;
  padding: 9px 26px 7px;
}
</style>
